import React, { useState, useEffect } from 'react';
import {
  Button, IconButton, Paper, Box, Toolbar, Typography, Card, CardContent, CardActions, Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import rentalServiceAccessService from '../services/rentalServiceAccessService';
import rentalService from '../services/rentalService';
import RentalServiceAccessForm from './RentalServiceAccessForm';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import Sidebar from './Sidebar';

const RentalServiceAccessList = () => {
  const [accessList, setAccessList] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [editingAccess, setEditingAccess] = useState(null);
  const [open, setOpen] = useState(false);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (rentals.length > 0) {
      fetchAllAccessLists(rentals).then(setAccessList);
    }
  }, [rentals]);

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchAllAccessLists(rentals) {
    try {
      const accessLists = await Promise.all(
        rentals.map(rental => fetchAccessList(rental.id))
      );
      // Flatten the array of arrays into a single array
      return accessLists.reduce((acc, list) => acc.concat(list), []);
    } catch (error) {
      console.error("Error fetching access lists:", error);
      return [];
    }
  }

  const fetchAccessList = async (rentalId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalServiceAccessService.getAccessList(token, rentalId);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleCreate = async (access) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalServiceAccessService.createAccess(token, access.rental_id, access);
      fetchAllAccessLists(rentals).then(setAccessList);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (access) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalServiceAccessService.updateAccess(token, access.rental_id, access.id, access);
      setEditingAccess(null);
      fetchAllAccessLists(rentals).then(setAccessList);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (access) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalServiceAccessService.deleteAccess(token, access.rental_id, access.id);
      fetchAllAccessLists(rentals).then(setAccessList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = (access) => {
    setEditingAccess(access);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingAccess(null);
  };

  const handleFormSubmit = (access) => {
    if (editingAccess) {
      handleUpdate(access);
    } else {
      handleCreate(access);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        toggleCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
        onLogout={() => console.log('Logout')}
      />
      <Box sx={{ flexGrow: 1, padding: '16px' }}>
        <Toolbar />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(null)}
            style={{ marginRight: '8px', marginTop: '16px' }}
          >
            Add Access
          </Button>
        </Box>

        <Grid container spacing={2}>
          {accessList.map((access) => {
            console.log("Access Item:", access);
            return (
              <Grid item xs={12} sm={6} md={4} key={access.id || access.someUniqueProperty}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{access.name || "No Name"}</Typography>
                    <Typography color="textSecondary">Rental: {access.rental.rental_name || "N/A"}</Typography>
                    <Typography color="textSecondary">Pin Code: {access.pin_code || "N/A"}</Typography>
                    <Typography color="textSecondary">
                      From: {moment(access.from_date).format('MMM DD YYYY, hh:mm A')}
                    </Typography>
                    <Typography color="textSecondary">
                      To: {moment(access.to_date).format('MMM DD YYYY, hh:mm A')}
                    </Typography>
                    <Typography color="textSecondary">Notes: {access.notes || "N/A"}</Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton onClick={() => handleOpen(access)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(access)}>
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <RentalServiceAccessForm
          open={open}
          onClose={handleClose}
          onSubmit={handleFormSubmit}
          initialData={editingAccess}
          rentals={rentals}
        />
      </Box>
    </Box>
  );
};

export default RentalServiceAccessList;