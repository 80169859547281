// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import BookingList from './components/BookingList';
import RentalList from './components/RentalList';
import RentalDetails from './components/RentalDetails';
import Dashboard from './components/Dashboard';
import PrivateRoute from './PrivateRoute';
import APIKeyList from './components/APIKeyList';
import GuestDashboard from './components/GuestDashboard';
import GoogleAnalytics from './components/GoogleAnalytics';
import RentalServiceAccessList from './components/RentalServiceAccessList';
import './index.css'
const REACT_APP_GOOGLE_GA4_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_GA4_MEASUREMENT_ID;



function App() {
  return (
    <div className="App">
      <Router>
      <GoogleAnalytics trackingId={REACT_APP_GOOGLE_GA4_MEASUREMENT_ID} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guest/:id/dashboard" element={<GuestDashboard  />} />
          <Route path="/dashboard"element={<PrivateRoute><Dashboard /></PrivateRoute>}/>
          <Route path="/bookings"element={<PrivateRoute><BookingList /></PrivateRoute>}/>
          <Route path="/rentals"element={<PrivateRoute><RentalList /></PrivateRoute>}/>
          <Route path="/rentals/:id"element={<PrivateRoute><RentalDetails /></PrivateRoute>}/>
          <Route path="/apikeys"element={<PrivateRoute><APIKeyList /></PrivateRoute>}/>
          <Route path="/access"element={<PrivateRoute><RentalServiceAccessList /></PrivateRoute>}/>
        </Routes>
      </Router>
    </div>

  );
}

export default App;
