import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';

const initialFormState = {
  rental_id: '',
  name: '',
  pin_code: '',
  from_date: dayjs(),
  to_date: dayjs(),
  notes: '',
};

const RentalServiceAccessForm = ({ open, onClose, onSubmit, initialData }) => {
  const [formState, setFormState] = useState(initialFormState);
  const [rentals, setRentals] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (!open) {
      setFormState(initialFormState);
    }
    if (initialData) {
      setFormState({    
        id: initialData.id || '',
        rental_id: initialData.rental_id || '',
        name: initialData.name || '',
        pin_code: initialData.pin_code || '',
        from_date: dayjs(initialData.from_date) || dayjs(),
        to_date: dayjs(initialData.to_date) || dayjs(),
        notes: initialData.notes || '',
      });
    }
  }, [initialData, open]);

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    } catch (error) {
      console.error('Error fetching rentals:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (field, value) => {
    console.log(`Changing ${field} to:`, value);
    setFormState((prevState) => ({ ...prevState, [field]: dayjs(value) }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formState);
    if (formState.rental_id) {
      console.log('Calling onSubmit with:', formState);
      onSubmit(formState);
    } else {
      console.error('Rental ID is required');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{initialData ? 'Edit Access' : 'Add Access'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth required>
            <InputLabel id="rental-select-label">Select Rental</InputLabel>
            <Select
              labelId="rental-select-label"
              name="rental_id"
              value={formState.rental_id}
              onChange={handleChange}
              label="Select Rental"
            >
              {rentals.map((rental) => (
                <MenuItem key={rental.id} value={rental.id}>
                  {rental.rental_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            required
          />
          <TextField
            label="Pin Code"
            name="pin_code"
            value={formState.pin_code}
            onChange={handleChange}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="From Date"
              value={formState.from_date}
              onChange={(value) => handleDateChange('from_date', value)}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <DateTimePicker
              label="To Date"
              value={formState.to_date}
              onChange={(value) => handleDateChange('to_date', value)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
          <TextField
            label="Notes"
            name="notes"
            value={formState.notes}
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {initialData ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RentalServiceAccessForm; 