import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const getAccessList = (token, rentalId) => {
  return axios.get(`${API_URL}/rentals/${rentalId}/access`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createAccess = (token, rentalId, access) => {
  return axios.post(`${API_URL}/rentals/${rentalId}/access`, access, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateAccess = (token, rentalId, accessId, access) => {
  return axios.put(`${API_URL}/rentals/${rentalId}/access/${accessId}`, access, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deleteAccess = (token, rentalId, accessId) => {
  return axios.delete(`${API_URL}/rentals/${rentalId}/access/${accessId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getAccessList,
  createAccess,
  updateAccess,
  deleteAccess,
}; 