import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from './Sidebar';
import bookingService from '../services/bookingService';
import rentalService from '../services/rentalService';
import CopyToClipboardButton from './CopyToClipboard';
import { format, isAfter, isBefore, parseISO, compareAsc, subMonths, startOfMonth, endOfMonth, addMonths, format as formatDate } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Paper, List, ListItem, ListItemText, Divider, Link, Grid, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BookingForm from './BookingForm';
import moment from 'moment';
import { LineChart, Line } from 'recharts';

const Dashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [currentMonthBookings, setCurrentMonthBookings] = useState(0);
  const [nextBookings, setNextBookings] = useState([]);
  const [currentActiveBookings, setCurrentActiveBookings] = useState([]);
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const { logout, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [editingBooking, setEditingBooking] = useState(null);
  const [editingBookingRental, setEditingBookingRental] = useState(null);
  const [rentals, setRentals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDateRange, setChartDateRange] = useState({
    from: subMonths(new Date(), 12),
    to: addMonths(new Date(), 3)
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const token = await getAccessTokenSilently();
      const response = await bookingService.getBookings(token);
      const bookingData = response.data;

      // Filter bookings based on the current month
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const bookingsThisMonth = bookingData.filter((booking) => {
        const checkIn = parseISO(booking.check_in);
        return isAfter(checkIn, startOfMonth) && isBefore(checkIn, endOfMonth);
      });

      setCurrentMonthBookings(bookingsThisMonth.length);

      // Group bookings by rental
      const groupedBookings = bookingData.reduce((acc, booking) => {
        const rentalName = booking.rental.rental_name || 'Unknown Rental';
        if (!acc[rentalName]) acc[rentalName] = [];
        acc[rentalName].push(booking);
        return acc;
      }, {});

      // Find the next booking for each rental property
      const nextBookingsForAllRentals = Object.keys(groupedBookings).map(rental => {
        const futureBookings = groupedBookings[rental]
          .filter(booking => isAfter(parseISO(booking.check_in), now))
          .sort((a, b) => compareAsc(parseISO(a.check_in), parseISO(b.check_in)));

        return futureBookings.length > 0 ? {
          rental: rental,
          booking: futureBookings[0],
        } : null;
      }).filter(Boolean);
      setNextBookings(nextBookingsForAllRentals);

      // Find the current active booking for each rental property
      const currentActiveForAllRentals = Object.keys(groupedBookings).map(rental => {
        const activeBooking = groupedBookings[rental].find(booking => {
          const checkIn = parseISO(booking.check_in);
          const checkOut = parseISO(booking.check_out);
          return isBefore(checkIn, now) && isAfter(checkOut, now);
        });
        return activeBooking ? { rental: rental, booking: activeBooking } : null;
      }).filter(Boolean);
      setCurrentActiveBookings(currentActiveForAllRentals);

      // Calculate bookings per month for the last 12 months + 3 future months
      const monthsRange = 12 + 3; // 12 past months + 3 future months
      const bookingsByMonth = [];

      for (let i = 0; i < monthsRange; i++) {
        const month = subMonths(now, i - 3); // shift to include future months
        const bookingsInMonth = bookingData.filter(booking => {
          const checkIn = parseISO(booking.check_in);
          return checkIn.getMonth() === month.getMonth() && checkIn.getFullYear() === month.getFullYear();
        });
        bookingsByMonth.push({
          month: format(month, 'MMM yyyy'),
          count: bookingsInMonth.length,
          isFuture: isAfter(month, now) // Mark future months
        });
      }

      setMonthlyBookings(bookingsByMonth.reverse());

      setBookings(bookingData);
    };

    fetchBookings();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchRentals = async () => {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    };

    fetchRentals();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const params = {
          'check-in-from': formatDate(chartDateRange.from, 'yyyy-MM-dd'),
          'check-in-to': formatDate(chartDateRange.to, 'yyyy-MM-dd')
        };
        
        const response = await bookingService.getBookings(token, params);
        const bookings = response.data;

        // Process bookings into monthly data
        const monthlyData = processBookingsIntoMonthlyData(bookings, chartDateRange.from, chartDateRange.to);
        setChartData(monthlyData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [getAccessTokenSilently, chartDateRange]);

  // Helper function to process bookings into monthly data
  const processBookingsIntoMonthlyData = (bookings, startDate, endDate) => {
    const data = [];
    let currentDate = startOfMonth(startDate);
    const lastDate = endOfMonth(endDate);

    while (currentDate <= lastDate) {
      const monthStart = startOfMonth(currentDate);
      const monthEnd = endOfMonth(currentDate);

      const monthBookings = bookings.filter(booking => {
        const bookingDate = new Date(booking.check_in);
        return bookingDate >= monthStart && bookingDate <= monthEnd;
      });

      data.push({
        date: formatDate(currentDate, 'MMM yyyy'),
        bookings: monthBookings.length,
        isFuture: currentDate > new Date()
      });

      currentDate = addMonths(currentDate, 1);
    }

    return data;
  };

  const handleEdit = (booking) => {
    setEditingBooking(booking);
    setEditingBookingRental(booking.rental);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingBooking(null);
    setEditingBookingRental(null);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    
    try {
      const token = await getAccessTokenSilently();
      const formData = {
        rental_id: form.rental_id.value,
        check_in: new Date(`${form.check_in_date.value}T${form.check_in_time.value}:00`).toISOString(),
        check_out: new Date(`${form.check_out_date.value}T${form.check_out_time.value}:00`).toISOString(),
        guest_name: form.guest_name.value,
        guest_email: form.guest_email.value,
        phone_number: form.guest_phone_number.value,
        pincode: form.guest_pincode.value,
      };

      await bookingService.updateBooking(token, editingBooking.id, formData);
      
      // Refresh the bookings data
      const response = await bookingService.getBookings(token);
      setBookings(response.data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const renderChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date"
          padding={{ left: 0, right: 0 }}
        />
        <YAxis 
          padding={{ top: 10, bottom: 0 }}
        />
        <Tooltip />
        <Bar dataKey="bookings">
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.isFuture ? "#82ca9d" : "#8884d8"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        toggleCollapse={toggleCollapse}
        onLogout={() => console.log('Logout')}
        title="Dashboard"
      />
      <div style={{ padding: '16px', transition: 'margin-left 0.3s' }}>
        <Box
          sx={{
            flex: 1,
            marginTop: '64px',
            transition: 'margin-left 0.3s',
          }}
        ></Box>
        {isAuthenticated ? (
          <>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>

              {/* Current Active Booking Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Current Active Booking</h3>
                  {currentActiveBookings.length > 0 ? (
                    <List>
                      {currentActiveBookings.map(({ rental, booking }, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemText
                              primary={rental}
                              secondary={
                                <>
                                  <div>Guest: {booking.guest_name}</div>
                                  <div>Check-Out: {format(parseISO(booking.check_out), 'PPP')}</div>
                                  <div>
                                    <Link href={booking.guest_dashboard_link} target="_blank" rel="noopener">
                                      Guest Dashboard
                                    </Link>
                                    <CopyToClipboardButton url={booking.guest_dashboard_link} />
                                    <IconButton 
                                      size="small" 
                                      onClick={() => handleEdit(booking)}
                                      sx={{ ml: 1 }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </>
                              }
                            />
                          </ListItem>
                          {index < currentActiveBookings.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <p>No active bookings</p>
                  )}
                </Paper>
              </Grid>

              {/* Next Booking Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Next Booking</h3>
                  {nextBookings.length > 0 ? (
                    <List>
                      {nextBookings.map(({ rental, booking }, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemText
                              primary={rental}
                              secondary={
                                <>
                                  <div>Guest: {booking.guest_name}</div>
                                  <div>Check-In: {format(parseISO(booking.check_in), 'PPP')}</div>
                                  <div>
                                    <Link href={booking.guest_dashboard_link} target="_blank" rel="noopener">
                                      Guest Dashboard
                                    </Link>
                                    <CopyToClipboardButton url={booking.guest_dashboard_link} />
                                    <IconButton 
                                      size="small" 
                                      onClick={() => handleEdit(booking)}
                                      sx={{ ml: 1 }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </>
                              }
                            />
                          </ListItem>
                          {index < nextBookings.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <p>No upcoming bookings</p>
                  )}
                </Paper>
              </Grid>

              {/* Bookings This Month Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ textAlign: 'left' }}>Bookings This Month</h3>
                  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '48px', fontWeight: 'bold', textAlign: 'center' }}>
                      {currentMonthBookings}
                    </p>
                  </div>
                </Paper>
              </Grid>

              {/* Bookings by Month Chart */}
              <Grid item xs={12}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Booking History</h3>
                  {renderChart()}
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <p>You are not logged in.</p>
        )}
      </div>
      <BookingForm
        open={open}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
        editingBooking={editingBooking}
        editingBookingRental={editingBookingRental}
        rentals={rentals}
      />
    </div>
  );
};

export default Dashboard;

